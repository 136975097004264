import React, { ReactElement, useState } from 'react';
import BceData from '../../assets/component_dimensions/BceData.svg';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import { useHandleInputChange, useHandleOnBlurChange } from './InputGroupHooks';
import { BowCableInputs, CalculationSectionProps } from './inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CalculationDataInputs } from '../CalculationsTab/calculationsTabTypes';

const initialBceData: BowCableInputs = {
  title: 'BCE',
  data: {
    h1distance: {
      name: 'Vertical distance A to B',
      symbol: 'H_1',
      value: 15,
      unit: 'm',
    },
    h2distance: {
      name: 'Vertical distance C to D',
      symbol: 'H_2',
      value: 15,
      unit: 'm',
    },
    w1distance: {
      name: 'Horizontal distance A to B',
      symbol: 'W_1',
      value: 25,
      unit: 'm',
    },
    w2distance: {
      name: 'Horizontal distance C to D',
      symbol: 'W_2',
      value: 5,
      unit: 'm',
    },
    frictionCoef: {
      name: 'Friction Coeff. of Arm Roller Bearings',
      symbol: 'µ_rb',
      value: 0.15,
      unit: '',
    },
  },
};

const bowCableLimits: Record<
  keyof BowCableInputs['data'],
  { min: number; max: number }
> = {
  frictionCoef: { min: 0, max: 10 },
  h1distance: { min: 1, max: 1000 },
  h2distance: { min: 1, max: 1000 },
  w1distance: { min: 1, max: 1000 },
  w2distance: { min: 1, max: 1000 },
};
function validateValue<K extends keyof BowCableInputs['data']>(
  key: K,
  value: number
): boolean {
  const limits = bowCableLimits[key];
  return value >= limits.min && value <= limits.max;
}

export function BowCableEngine(props: CalculationSectionProps): ReactElement {
  const initialData: CalculationDataInputs =
    props.applicationData.calculationsData.find(
      (section: CalculationDataInputs): boolean => section.id === props.id
    ) || initialBceData;
  const [bowCableData, setBowCableData] = useState<BowCableInputs>(
    initialData as BowCableInputs
  );

  const handleInputChange = useHandleInputChange(setBowCableData);
  const handleOnBlurChange = useHandleOnBlurChange({
    id: props.id,
    data: bowCableData,
    calculationDataState: props.calculationDataState,
  });

  return (
    <>
      <StyledDiv>
        <InputSection>
          <CategoryHeader title="BCE (Bow Cable Engine)" underline={false} />
          {Object.entries(bowCableData.data).map(
            ([key, input]: [string, DataInputs]) => (
              <DataInput
                key={key}
                name={input.name}
                symbol={input.symbol}
                value={input.value}
                placeholder={input.value}
                unit={input.unit}
                valueOk={validateValue(
                  key as keyof BowCableInputs['data'],
                  input.value
                )}
                onChange={handleInputChange(
                  key as keyof BowCableInputs['data']
                )}
                onBlur={handleOnBlurChange}
              />
            )
          )}
        </InputSection>
        <div>
          <img src={BceData} alt={''} />
        </div>
      </StyledDiv>
    </>
  );
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 0.8em;
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
