import React, { ReactElement } from 'react';
import {
  CableElementDescription,
  CableElements,
  elementImages,
  ScenarioBannerProps,
  scenarioElements,
} from './scenarioTypes';
import { ImageLabel } from '../Labels/ImageLabel';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';

export function ScenarioBanner(props: ScenarioBannerProps): ReactElement {
  const elements: CableElements[] = scenarioElements[props.scenario];
  return (
    <StyledDiv>
      <ScenarioDiv>
        {elements.map((element: CableElements, index: number) => (
          <ImageContainer key={`${index} - ${element}`}>
            <ResponsiveImg
              key={index}
              src={elementImages[element]}
              alt={element}
            />
            <ImageLabel title={CableElementDescription[element]} />
          </ImageContainer>
        ))}
      </ScenarioDiv>
      <CategoryHeader title={props.scenario} underline={false} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.8em;
`;
const ScenarioDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 0.5em;
  box-sizing: border-box;
  padding-top: 3em;
  padding-bottom: 4em;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: 100%;
`;

const ResponsiveImg = styled.img`
  height: 170px;
  width: auto;
  @media (max-width: 840px) {
    height: 125px;
  }

  @media (max-width: 640px) {
    height: 100px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 0.6em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
