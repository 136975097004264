import React, { ReactElement } from 'react';
import styled from 'styled-components';
import checkMark from '../../assets/buttons_and_icons/checkMark.svg';
import wrongMark from '../../assets/buttons_and_icons/wrongMark.svg';
import { InputProps } from './InputTypes';
import TooltipIcon from '../../assets/buttons_and_icons/TooltipIcon.svg';
import { IconButton } from '../Button/IconButton';
import { FrictionTooltip } from '../Tooltip/FrictionTooltip';

export function DataInput(props: InputProps): ReactElement {
  return (
    <StyledDataLabel>
      <NameDiv>{props.name}</NameDiv>
      {props.infoIcon && props.infoModal ? (
        <FrictionTooltip hoverContent={props.infoModal}>
          <IconButton imgSrc={TooltipIcon} onClick={props.infoCallback} />
        </FrictionTooltip>
      ) : null}
      <SymbolDiv>
        {props.symbol.split('_')[0]}
        <SubscriptText>{props.symbol.split('_')[1]}</SubscriptText>
      </SymbolDiv>
      <StyledInput
        type="number"
        placeholder={String(props.value)}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      <UnitsDiv>{props.unit}</UnitsDiv>
      {props.valueOk ? (
        <div>
          <img
            src={checkMark}
            alt=""
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      ) : (
        <div>
          <img
            src={wrongMark}
            alt=""
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      )}
    </StyledDataLabel>
  );
}

const StyledDataLabel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  height: 40px;
  max-width: 550px;
  min-width: 350px;
  gap: 0.8em;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
`;
const NameDiv = styled.div`
  flex-grow: 0;
`;
const SymbolDiv = styled.div`
  width: 50px;
  font-style: italic;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;
const SubscriptText = styled.span`
  vertical-align: super;
  position: relative;
  bottom: -0.5em;
  font-size: 80%;
`;

const UnitsDiv = styled.div`
  width: 50px;
`;
const StyledInput = styled.input`
  align-self: stretch;
  width: 80px;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  text-align: center;
  padding: 0.3em 0.8em 0.3em 0.8em;
  box-sizing: border-box;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
