import React, { ChangeEvent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { DataInput } from '../Inputs/DataInput';
import CableData from '../../assets/component_dimensions/CableData.svg';
import { useAppData } from '../Contexts/DataContext/DataContext';
import { ApplicationDataType } from '../Contexts/DataContext/dataContextType';
import { CableDataProps } from '../InputGroup/inputGroupTypes';
import { DataInputs } from '../Inputs/InputTypes';
import { CableDataInputs } from './rightSectionTypes';

const valueLimits: Record<keyof CableDataInputs, { min: number; max: number }> =
  {
    bendRadius: { max: 1000, min: 0 },
    bendingStiffness: { max: 10000, min: 0 },
    designSafetyFactor: { max: 100, min: 0 },
    diameter: { max: 10000, min: 0 },
    mass: { max: 1000, min: 0 },
  };

function validateValue<K extends keyof CableDataInputs>(
  key: K,
  value: number
): boolean {
  const limits = valueLimits[key];
  return value >= limits.min && value <= limits.max;
}
export function CableDataDiv(props: CableDataProps): ReactElement {
  const { applicationData, setApplicationData } = useAppData();
  const [cableData, setCableData] = useState<CableDataInputs>(
    applicationData.cableData
  );

  const handleInputChange =
    (field: keyof ApplicationDataType['cableData']) =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      setCableData({
        ...cableData,
        [field]: {
          ...cableData[field],
          value: Number(value),
        },
      });
    };
  const handleOnBlurChange = (): void => {
    const updatedAppData: ApplicationDataType = applicationData;
    updatedAppData.cableData = cableData;
    setApplicationData(updatedAppData);
  };

  return (
    <StyledDiv>
      <InputSection>
        <CategoryHeader title={props.title} underline={false} />
        {Object.entries(cableData)
          .filter(([key]): boolean => key !== 'designSafetyFactor')
          .map(([key, input]: [string, DataInputs]) => (
            <DataInput
              key={key}
              name={input.name}
              symbol={input.symbol}
              value={input.value}
              placeholder={input.value}
              unit={input.unit}
              valueOk={validateValue(key as keyof CableDataInputs, input.value)}
              onChange={handleInputChange(key as keyof CableDataInputs)}
              onBlur={handleOnBlurChange}
            />
          ))}
        <div>
          <img src={CableData} alt={props.title} />
        </div>
        <CategoryHeader title="General Input" underline={false} />
        <DataInput
          name={applicationData.cableData.designSafetyFactor.name}
          symbol={applicationData.cableData.designSafetyFactor.symbol}
          value={applicationData.cableData.designSafetyFactor.value}
          placeholder={applicationData.cableData.designSafetyFactor.value}
          unit={applicationData.cableData.designSafetyFactor.unit}
          valueOk={validateValue(
            'designSafetyFactor',
            applicationData.cableData.designSafetyFactor.value
          )}
          onChange={handleInputChange('designSafetyFactor')}
          onBlur={handleOnBlurChange}
        />
      </InputSection>
    </StyledDiv>
  );
}

export function CableDataTab(): ReactElement {
  return (
    <>
      <CableDataDiv title="Cable Data" />
    </>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`;
