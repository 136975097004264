import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { CategoryHeader } from '../Labels/CategoryHeader';
import { useScenario } from '../Contexts/ScenarioContext/ScenarioContext';
import { SubmitButton } from '../Button/SubmitButton';
import { useAppData } from '../Contexts/DataContext/DataContext';
import { ReportData } from '../ReportsTab/reportsTabTypes';
import { fetchReport } from '../../services/swegApi';
import { ScenarioName } from '../ScenarioBanner/scenarioTypes';
import dividerLine from '../../assets/other/dividerLine.svg';
import LoadingSpinner from '../../assets/other/LoadingSpinner.svg';
import { ScenarioResultSection } from './ScenarioResults';
import {
  BceResultLabelsScenario1,
  BceResultLabelsScenario2,
  initialBceResultScenario1,
  initialBceResultScenario2,
  initialLceResultsScenario1,
  initialLceResultsScenario3,
  initialLceResultsScenario4a,
  LceResultsLabelsScenario1,
  LceResultsLabelsScenario3,
  LceResultsLabelsScenario4a,
} from './resultsScenarios';
import { updateResults } from './ResultsDataHooks';

export function ResultsData(): ReactElement {
  const { selectedScenario } = useScenario();
  const {
    applicationData,
    appResultsData,
    projectData,
    resultsLoading,
    isDataValid,
    setResultsLoading,
  } = useAppData();

  const [scenario1Results, setScenario1Results] = useState([
    LceResultsLabelsScenario1,
    BceResultLabelsScenario1,
  ]);
  const [scenario2Results, setScenario2Results] = useState([
    BceResultLabelsScenario1,
    BceResultLabelsScenario2,
  ]);
  const [scenario3Results, setScenario3Results] = useState([
    LceResultsLabelsScenario3,
    BceResultLabelsScenario1,
  ]);
  const [scenario4AResults, setScenario4AResults] = useState([
    LceResultsLabelsScenario1,
    LceResultsLabelsScenario4a,
  ]);
  const [scenario4BResults, setScenario4BResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
    LceResultsLabelsScenario4a,
  ]);
  const [scenario5AResults, setScenario5AResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
    LceResultsLabelsScenario4a,
  ]);
  const [scenario5BResults, setScenario5BResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
    BceResultLabelsScenario1,
  ]);
  const [scenario6AResults, setScenario6AResults] = useState([
    BceResultLabelsScenario1,
  ]);
  const [scenario6BResults, setScenario6BResults] = useState([
    BceResultLabelsScenario1,
    LceResultsLabelsScenario3,
  ]);

  const scenarioOutput: Record<ScenarioName, ReactElement> = {
    'Scenario 1: Vessel to Tank': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario1Results[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-1"
          data={scenario1Results[1]}
          fallbackData={isDataValid}
        />
      </>
    ),

    'Scenario 2: Tank to Tank': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario2Results[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-2"
          data={scenario2Results[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 3: Factory to Tank': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario3Results[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-1"
          data={scenario3Results[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 4a: Vessel to Vessel (CAT)': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario4AResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-2"
          data={scenario4AResults[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 4b: Vessel to Vessel (BCE)': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario4BResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario4BResults[1]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-2"
          data={scenario4BResults[2]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 5a: Tank to Vessel (CAT)': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario5AResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario5AResults[1]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-2"
          data={scenario5AResults[2]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 5b: Tank to Vessel (BCE)': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario5BResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario5BResults[1]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="BCE-2"
          data={scenario5BResults[2]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 6a: Vessel to Reel': (
      <>
        <ScenarioResultSection
          title="LCE-1"
          data={scenario6AResults[0]}
          fallbackData={isDataValid}
        />
      </>
    ),
    'Scenario 6b: Tank to Reel': (
      <>
        <ScenarioResultSection
          title="BCE-1"
          data={scenario6BResults[0]}
          fallbackData={isDataValid}
        />
        <ScenarioResultSection
          title="LCE-1"
          data={scenario6BResults[1]}
          fallbackData={isDataValid}
        />
      </>
    ),
  };

  const handleSubmitBtnClicked = () => {
    setResultsLoading(true);
    const reportData: ReportData = {
      calculationsData: applicationData,
      projectData: projectData,
    };
    const reportName = `${reportData.projectData.infoSection.projectTitle}-Cable Transfer Analysis-${reportData.projectData.infoSection.calculationNumber}-${reportData.projectData.infoSection.revision}`;
    void fetchReport(reportData, reportName).then(() =>
      setResultsLoading(false)
    );
  };

  useEffect(() => {
    switch (selectedScenario) {
      case 'Scenario 1: Vessel to Tank':
        updateResults(
          appResultsData,
          [initialLceResultsScenario1, initialBceResultScenario1],
          setScenario1Results,
          [LceResultsLabelsScenario1, BceResultLabelsScenario1]
        );
        break;
      case 'Scenario 2: Tank to Tank':
        updateResults(
          appResultsData,
          [initialBceResultScenario1, initialBceResultScenario2],
          setScenario2Results,
          [BceResultLabelsScenario1, BceResultLabelsScenario2]
        );
        break;
      case 'Scenario 3: Factory to Tank':
        updateResults(
          appResultsData,
          [initialLceResultsScenario3, initialBceResultScenario1],
          setScenario3Results,
          [LceResultsLabelsScenario3, BceResultLabelsScenario1]
        );
        break;
      case 'Scenario 4a: Vessel to Vessel (CAT)':
        updateResults(
          appResultsData,
          [initialLceResultsScenario1, initialLceResultsScenario4a],
          setScenario4AResults,
          [LceResultsLabelsScenario1, LceResultsLabelsScenario4a]
        );
        break;
      case 'Scenario 4b: Vessel to Vessel (BCE)':
        updateResults(
          appResultsData,
          [
            initialBceResultScenario1,
            initialLceResultsScenario3,
            initialLceResultsScenario4a,
          ],
          setScenario4BResults,
          [
            BceResultLabelsScenario1,
            LceResultsLabelsScenario3,
            LceResultsLabelsScenario4a,
          ]
        );
        break;
      case 'Scenario 5a: Tank to Vessel (CAT)':
        updateResults(
          appResultsData,
          [
            initialBceResultScenario1,
            initialLceResultsScenario3,
            initialLceResultsScenario4a,
          ],
          setScenario5AResults,
          [
            BceResultLabelsScenario1,
            LceResultsLabelsScenario3,
            LceResultsLabelsScenario4a,
          ]
        );
        break;
      case 'Scenario 5b: Tank to Vessel (BCE)':
        updateResults(
          appResultsData,
          [
            initialBceResultScenario1,
            initialLceResultsScenario3,
            initialBceResultScenario2,
          ],
          setScenario5BResults,
          [
            BceResultLabelsScenario1,
            LceResultsLabelsScenario3,
            BceResultLabelsScenario2,
          ]
        );
        break;
      case 'Scenario 6a: Vessel to Reel':
        updateResults(
          appResultsData,
          [initialLceResultsScenario1],
          setScenario6AResults,
          [LceResultsLabelsScenario1]
        );
        break;
      case 'Scenario 6b: Tank to Reel':
        updateResults(
          appResultsData,
          [initialBceResultScenario1, initialLceResultsScenario3],
          setScenario6BResults,
          [BceResultLabelsScenario1, LceResultsLabelsScenario3]
        );
        break;
    }
  }, [appResultsData, selectedScenario]);

  const memoizedScenarioOutput = useMemo(
    () => scenarioOutput[selectedScenario],
    [scenarioOutput, selectedScenario]
  );

  return (
    <StyledDiv>
      <CategoryHeader title="Results" underline={false} />
      <ResultsDiv>
        <CategoryHeader title={selectedScenario} underline={false} />
        <StyledDivider>
          <img
            src={dividerLine}
            alt=""
            style={{ height: '100%', width: '100%' }}
          />
        </StyledDivider>
        {memoizedScenarioOutput}
      </ResultsDiv>
      <ButtonDiv>
        {!resultsLoading ? (
          <SubmitButton
            title="Download Report"
            onClick={handleSubmitBtnClicked}
          />
        ) : (
          <SpinnerDiv>
            <img
              src={LoadingSpinner}
              alt=""
              style={{ height: '100%', width: '100%' }}
            />
          </SpinnerDiv>
        )}
      </ButtonDiv>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  gap: 0.8em;
  position: relative;
`;

const ResultsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding-bottom: 0.3em;
`;
const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
`;

const StyledDivider = styled.div`
  padding-bottom: 0.5em;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerDiv = styled.div`
  animation: ${rotate} 2s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
`;
