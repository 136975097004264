import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IconButton } from '../Button/IconButton';
import AddIcon from '../../assets/buttons_and_icons/AddIcon.svg';
import CopyIcon from '../../assets/buttons_and_icons/CopyIcon.svg';
import DeleteIcon from '../../assets/buttons_and_icons/DeleteIcon.svg';
import { ReferenceInputProps } from './InputTypes';

export function ReferenceInput(props: ReferenceInputProps): ReactElement {
  return (
    <>
      <StyledDataLabel>
        <NameDiv>{props.label}</NameDiv>
        <StyledInput
          name={props.name}
          type="text"
          value={props.value}
          placeholder={props.inputPlaceHolder}
          onChange={props.onChange}
        />
        <IconsDiv>
          <IconButton imgSrc={AddIcon} onClick={props.onAddClick} />
          <IconButton imgSrc={CopyIcon} onClick={props.onCopyClick} />
          <IconButton imgSrc={DeleteIcon} onClick={props.onDeleteClick} />
        </IconsDiv>
      </StyledDataLabel>
    </>
  );
}

const StyledDataLabel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  height: 40px;
  width: 100%;
  min-width: 350px;
  gap: 0.5em;
  color: #333333;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Arial, sans-serif;
  position: relative;
`;

const NameDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  gap: 0.8em;
`;

const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5em;
  justify-content: space-between;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledInput = styled.input`
  align-self: stretch;
  flex-grow: 1;
  border: rgb(204, 204, 204) solid 1px;
  border-radius: 5px;
  text-align: left;
  padding: 0.3em 0.8em 0.3em 0.8em;
  box-sizing: border-box;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    text-align: left;
  }
`;
