import BCE from '../../assets/scenario_components/BCE.svg';
import BceQuayVesselFront from '../../assets/scenario_components/BCE quay to vesselfront.svg';
import CatQuayVessel from '../../assets/scenario_components/Catenary quay to vessel.svg';
import CatVesselQuay from '../../assets/scenario_components/Catenary vessel to quay.svg';
import Factory from '../../assets/scenario_components/Factory.svg';
import LCE from '../../assets/scenario_components/LCE.svg';
import LceVesselSide from '../../assets/scenario_components/LCE vesselside.svg';
import MCT from '../../assets/scenario_components/MCT.svg';
import SpoolCableLeft from '../../assets/scenario_components/Spool cable left.svg';
import StorageCableLeft from '../../assets/scenario_components/Storage cable left.svg';
import StorageCableRight from '../../assets/scenario_components/Storage cable right.svg';
import StorageVesselFrontBce from '../../assets/scenario_components/Storage vesselfront BCE.svg';
import VesselEndLeft from '../../assets/scenario_components/Vessel end left side.svg';
import VesselEndRight from '../../assets/scenario_components/Vessel end right side.svg';

export type CableElements =
  | 'BCE'
  | 'BceQuayVesselFront'
  | 'CatQuayVessel'
  | 'CatVesselQuay'
  | 'Factory'
  | 'LCE'
  | 'LceVesselSide'
  | 'MCT'
  | 'SpoolCableLeft'
  | 'StorageCableLeft'
  | 'StorageCableRight'
  | 'StorageVesselFrontBce'
  | 'VesselEndLeft'
  | 'VesselEndRight';

export const CableElementDescription: Record<CableElements, string> = {
  BCE: 'BCE',
  BceQuayVesselFront: 'BCE',
  CatQuayVessel: 'Catenary Quay to Vessel',
  CatVesselQuay: 'Catenary Vessel to Quay',
  Factory: 'Factory',
  LCE: 'LCE',
  LceVesselSide: 'LCE',
  MCT: 'MCT',
  SpoolCableLeft: 'Reel',
  StorageCableLeft: 'Tank',
  StorageCableRight: 'Tank',
  StorageVesselFrontBce: 'Tank',
  VesselEndLeft: 'Vessel',
  VesselEndRight: 'Vessel',
};

export const elementImages: Record<CableElements, string> = {
  BCE,
  BceQuayVesselFront,
  CatQuayVessel,
  CatVesselQuay,
  Factory,
  LCE,
  LceVesselSide,
  MCT,
  SpoolCableLeft,
  StorageCableLeft,
  StorageCableRight,
  StorageVesselFrontBce,
  VesselEndLeft,
  VesselEndRight,
};

export type ScenarioName =
  | 'Scenario 1: Vessel to Tank'
  | 'Scenario 2: Tank to Tank'
  | 'Scenario 3: Factory to Tank'
  | 'Scenario 4a: Vessel to Vessel (CAT)'
  | 'Scenario 4b: Vessel to Vessel (BCE)'
  | 'Scenario 5a: Tank to Vessel (CAT)'
  | 'Scenario 5b: Tank to Vessel (BCE)'
  | 'Scenario 6a: Vessel to Reel'
  | 'Scenario 6b: Tank to Reel';

export const scenarios: ScenarioName[] = [
  'Scenario 1: Vessel to Tank',
  'Scenario 2: Tank to Tank',
  'Scenario 3: Factory to Tank',
  'Scenario 4a: Vessel to Vessel (CAT)',
  'Scenario 4b: Vessel to Vessel (BCE)',
  'Scenario 5a: Tank to Vessel (CAT)',
  'Scenario 5b: Tank to Vessel (BCE)',
  'Scenario 6a: Vessel to Reel',
  'Scenario 6b: Tank to Reel',
];

export const scenarioElements: Record<ScenarioName, CableElements[]> = {
  'Scenario 1: Vessel to Tank': [
    'VesselEndLeft',
    'CatVesselQuay',
    'MCT',
    'LCE',
    'BCE',
    'StorageCableLeft',
  ],
  'Scenario 2: Tank to Tank': [
    'StorageCableRight',
    'BCE',
    'MCT',
    'BCE',
    'StorageCableLeft',
  ],
  'Scenario 3: Factory to Tank': [
    'Factory',
    'MCT',
    'LCE',
    'BCE',
    'StorageCableLeft',
  ],
  'Scenario 4a: Vessel to Vessel (CAT)': [
    'VesselEndLeft',
    'CatVesselQuay',
    'MCT',
    'LCE',
    'CatQuayVessel',
    'LceVesselSide',
    'VesselEndRight',
  ],
  'Scenario 4b: Vessel to Vessel (BCE)': [
    'StorageVesselFrontBce',
    'MCT',
    'LCE',
    'CatQuayVessel',
    'LceVesselSide',
    'VesselEndRight',
  ],
  'Scenario 5a: Tank to Vessel (CAT)': [
    'StorageCableRight',
    'BCE',
    'MCT',
    'LCE',
    'CatQuayVessel',
    'LceVesselSide',
    'VesselEndRight',
  ],
  'Scenario 5b: Tank to Vessel (BCE)': [
    'StorageCableRight',
    'BCE',
    'MCT',
    'LCE',
    'BceQuayVesselFront',
  ],
  'Scenario 6a: Vessel to Reel': [
    'VesselEndLeft',
    'CatVesselQuay',
    'MCT',
    'LCE',
    'SpoolCableLeft',
  ],
  'Scenario 6b: Tank to Reel': [
    'StorageCableRight',
    'BCE',
    'MCT',
    'LCE',
    'SpoolCableLeft',
  ],
};

export interface ScenarioBannerProps {
  scenario: ScenarioName;
}
